// import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'


const Footer = ({ siteTitle, className }) => (
    <div 
    className={className}
    >Joc.is Copyright 2021</div>
)

const StyledFooter = styled(Footer)`
    color: purple;
`
export default StyledFooter